import React, { useEffect } from 'react'
import '../styles/experiences.scss'
import PNG from '../assets/png.png'
import Dripperz from '../assets/Dripperz.png'
import Farandole from '../assets/farandole.png'
import Ondefy from '../assets/ondefy.png'
import Hyro from '../assets/hyro.png'
import Room from '../assets/room.png'
import { useStateContext } from "../contexts/ContextProvider"
import Experience from './Experience'
import { useDetectScroll } from "@smakss/react-scroll-direction"

function Experiences() {
    const { setId, setModal, modal } = useStateContext()
    const [scrollDir] = useDetectScroll({ axis: 'y' });

    const toggleModal = () => {
        setModal(!modal)
    }
    useEffect(() => {
        if (scrollDir === 'down' || scrollDir === 'up') {
            setModal(false)
        }
    }, [scrollDir, setModal])

    return (
        <div data-aos="fade-up" data-aos-once="true">
            <div style={{ paddingTop: '100px' }} />
            <h1 className='neonText' style={{ textAlign: 'center' }}>Experiences</h1>
            <div className='exp-small'>
                <div onClick={() => { toggleModal(); setId(3) }}>
                    <Experience img={Ondefy} text="Ondefy SA" place='Remote' />
                </div>
                <div onClick={() => { toggleModal(); setId(1) }}>
                    <Experience img={PNG} text="Pangolin DEX" place='Remote' />
                </div>
                <div onClick={() => { toggleModal(); setId(2) }}>
                    <Experience img={Farandole} text="Farandole" place='Remote' />
                </div>
                <div onClick={() => { toggleModal(); setId(0) }}>
                    <Experience img={Dripperz} text="Dripperz" place='Remote' />
                </div>
                <div onClick={() => { toggleModal(); setId(4) }}>
                    <Experience img={Room} text="Room420" place='Remote' />
                </div>
            </div>
            <div style={{ paddingTop: '50px' }} />
            <h3 style={{ textAlign: 'center' }}>Hackaton</h3>
            <div className='exp-small' onClick={() => { toggleModal(); setId(5) }}>
                <Experience img={Hyro} text="Hyro Project" place='Paris' />
            </div>
        </div>
    )
}

export default Experiences