import React, { useState, useEffect } from 'react'
import Hero from './components/Hero'
import Skills from './components/Skills'
import ButtonSocial from './components/ButtonSocial'
import ModalSocial from './components/ModalSocial'
import Experiences from './components/Experiences'
import Contact from './components/Contact'
import About from './components/About'
import Connection from './components/Connection'
import { Web3Provider } from '@ethersproject/providers';
import { Web3ReactProvider } from '@web3-react/core';
import Aos from "aos"
import Title from './components/Title'
import { ParallaxProvider, Parallax } from 'react-scroll-parallax';
import ModalExperience from './components/ModalExperience';
import { useStateContext } from "./contexts/ContextProvider"
import posthog from 'posthog-js'

//pallette:
// #000000
// #14213d
// #fca311
// #e5e5e5
// #FFF

function App() {
  const [modalSocial, setModalSocial] = useState(false);

  const { id, modal } = useStateContext()
  const toggleModal = () => {
    setModalSocial(!modalSocial)
  }

  const getLibrary = (provider) => {
    const library = new Web3Provider(provider, 'any');
    library.pollingInterval = 15000;
    return library;
  };

  useEffect(() => {
    Aos.init({
      duration: 2000
    });
  }, [])

  useEffect(() => {
    posthog.init('phc_M4YVm3X5eJHahQePvnFVLDaoHtqYQlpBOSoapHtx8yL', { api_host: 'https://app.posthog.com' });

    posthog.capture('my event', { property: 'connection' })
  }, [])

  return (
    <Web3ReactProvider getLibrary={getLibrary} >
      <ParallaxProvider>
        <div className='container'>
          {modalSocial && <ModalSocial toggleModal={toggleModal} />}
          {modal && <ModalExperience toggleModal={toggleModal} id={id} />}
          <div className="top">
            <Hero />
          </div>
          <div className='separator' />
          <Parallax translateX={[-100, 70]}>
            <Title handler="About me" />
          </Parallax>
          <About />
          <div className='separator' />
          <Parallax translateX={[-100, 70]}>
            <Title handler="Skills" />
          </Parallax>
          <Skills />
          <div className='separator' />
          <Parallax translateX={[-100, 40]}>
            <Title handler="Experiences" />
          </Parallax>
          <Experiences />
          <div className='separator' />
          <Parallax translateX={[-100, 40]}>
            <Title handler="Hover me..." />
          </Parallax>
          <Connection />
          <div className='separator' />
          <Contact />
          <ButtonSocial toggleModal={toggleModal} />
        </div>
      </ParallaxProvider>
    </Web3ReactProvider>
  );
}

export default App;
