import React from 'react'
import Banner from './Banner';

function Skills2() {
    return (
        <div data-aos="fade-up" data-aos-once="true">
            <h1 className='neonText' style={{ textAlign: 'center' }}>Skills</h1>
            <div style={{ paddingTop: '50px' }} />
            <Banner />
        </div>
    )
}

export default Skills2