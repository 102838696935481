import React, { useState } from 'react'
import SkillsJson from '../store/skills.json'
import Marquee from "react-fast-marquee";
import "../styles/banner.scss"
import { skillsText } from '../store/constant'


function Banner() {
  const [isShown, setIsShown] = useState(-1);

  const showSkillText = () => {
    return (
      <>
        {isShown !== -1 ?
          <p style={{ height: '20px' }} className='center-simple'>{skillsText[isShown]}</p>
          : isShown === -1 && <p style={{ height: '20px' }}></p>}
      </>
    )
  }
  return (
    <div>
      <Marquee speed={100} gradient={false} pauseOnHover>
        {SkillsJson.map((skill) => {
          return (
            <img key={skill.id} className='banner' src={skill.src} alt={skill.alt} style={{ borderRadius: skill.radius }} onMouseEnter={() => setIsShown(skill.id)} onMouseLeave={() => setIsShown(-1)} />
          )
        })}
      </Marquee>
      <div style={{ paddingTop: '30px' }}>
        {showSkillText()}
      </div>
    </div>
  )
}

export default Banner