import React from 'react'
import "aos/dist/aos.css"
import Carousel from './Carousel'

function About() {
    return (
        <div className='center-simple-col about' data-aos="fade-up" data-aos-once="true" style={{ paddingTop: '50px' }}>
            <h1 className='neonText'>About me</h1>
            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', padding: '20px', textAlign: 'center' }}>
                <p>
                    I am a front-end developer based in Paris, France.<br />
                    <Carousel />
                    Feel free to contact me on linkedin or my email.
                </p>
            </div>
        </div>
    )
}

export default About