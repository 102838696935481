import React from 'react'
import "../styles/carousel.scss"

function Carousel() {
  return (
    <div className='container-carousel'>
      <div className="center">
        <div className='carousel'>
          <div className='preTxt'>I am passionate about </div>
          <div className='changeHidden'>
            <div className='contenant'>
              <div className="element">Blockchain Techs.</div>
              <div className="element">Decentralization.</div>
              <div className="element">User Experience.</div>
              <div className="element">New technologies.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Carousel