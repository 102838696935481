import React from 'react'
import "../styles/experience.scss"
import { useMediaQuery } from "react-responsive";
import { useParallax } from 'react-scroll-parallax';

function Experience({ img, text, place }) {
  const isMobile = useMediaQuery({ maxWidth: 890 })
  const mage = useParallax({
    scale: [1, 1.2, 'easeInQuad'],
  });
  return (
    <div className='flex-row card' ref={mage.ref}>
      <div className='overlay'></div>
      <div>
        <h1 className='overlap'>{text}</h1>
        <div className='flex-row-between'>
          <p>Front-end developer</p>
          <p>{place}</p>
        </div>
      </div>
      <div>
        <img src={img} alt='ondefy' width={isMobile ? "80px" : "200px"} />
      </div>
    </div>
  )
}

export default Experience